<template>
  <div>
    <div class="main-p main-p6" v-show="true">
      <p class="tit hidden-sm-and-down">主办方认证</p>
      <div style="position: relative;">
        <div class="mask" v-show="shtc">正在审核中，请耐心等待，审核期间信息无法更改！</div>
        <p class="p6-der">
          请填写如下资料完成认证，完成认证后活动页将出现主办方头像，简介等资料,并带
          <span class="v-icon"></span>
          标志。
        </p>
        <div class="p6-form">
          <el-form :model="funlist" :rules="P6rules" ref="p6ruleForm" label-position="left" label-width="2.7777rem" class="demo-ruleForm">
            <el-form-item label="主办方头像" prop="tx" class="tx-form" ref="txupload">
              <el-upload
                class="avatar-uploader tx-upload"
                action="/FH-WEB/web/savefile.do"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="funlist.tx" :src="funlist.tx" class="avatar" />
                <i v-else class="el-icon-upload avatar-uploader-icon"><div class="el-upload__text">点击上传</div></i>
              </el-upload>
              <p class="tx-upload-tip">图片小于2M(JPG、PNG)推荐尺寸360*360px的图片</p>
            </el-form-item>
            <div class="p6-item-fra">
              <el-form-item label="机构中文名称" prop="zhname">
                <el-input v-model="funlist.zhname" placeholder="不超过24个字" maxlength="24" show-word-limit></el-input>
              </el-form-item>
              <el-form-item label="机构类型" prop="organtype">
                <el-select clearable v-model="p6setvalue" placeholder="请选择" class="p6-select">
                  <el-option v-for="item in p6setoptions" :key="item.CLASSIFY" :label="item.CLASSIFY" :value="item.CLASSIFY"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="机构英文名称" prop="enname">
              <el-input v-model="funlist.enname" placeholder="Less than 140 characters" maxlength="140" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="机构简介" prop="der">
              <el-input type="textarea" :rows="5" resize="none" v-model="funlist.der" placeholder="不超过220个字" maxlength="220" show-word-limit></el-input>
            </el-form-item>
            <!-- <el-form-item label="营业执照" prop="business">
              <el-upload
                class="upload-demo"
                drag
                action="/FH-WEB/web/savefile.do"
                multiple
                list-type="picture"
                :on-success="handlebusinessSuccess"
                :before-upload="beforebusinessUpload"
                :on-change="checkfilelist"
                :on-remove="checkfilelist"
                :limit="2"
                :file-list="filelist"
                :on-exceed="overLimit"
                ref="busupload"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  点击上传
                  <br />
                  图片小于4M(JPG、PNG)
                </div>
              </el-upload>
              <p class="business-tip">
                社会团体上传登记证书，企业上传营业执照，若办理过三证合一的企业，请上传最新的营业执照。格式要求：原件照片／扫描件或复印件加盖企业公章后的扫描件，
                <b>最多可上传2张</b>
              </p>
            </el-form-item> -->
            <el-form-item label="机构联系人" prop="contact"><el-input v-model="funlist.contact" maxlength="50" show-word-limit></el-input></el-form-item>
            <el-form-item label="机构电话" prop="phone">
              <el-input
                onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
                v-model="funlist.phone"
                placeholder="仅限输入数字,带区号请去掉横杠输入"
                maxlength="50"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人职位" prop="position"><el-input v-model="funlist.position" maxlength="50" show-word-limit></el-input></el-form-item>
            <el-form-item label="机构地址" prop="side"><el-input v-model="funlist.side" maxlength="50" show-word-limit></el-input></el-form-item>
            <el-form-item label="微信公众号ID" prop="wxid" class="clear-indent"><el-input v-model="funlist.wxid" maxlength="50" show-word-limit></el-input></el-form-item>
          </el-form>
        </div>
        <div class="form-sub">
          <div class="form-sub-btn" @click="p6submit()">提交资料</div>
          <!-- <div class="check">
          <el-checkbox v-model="checked">
            <span class="c-p1">我已阅读</span>
            <span class="c-p2">Cityplus趣活动主办方认证协议</span>
          </el-checkbox>
        </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sponsoratttest, savefile, listrzsp, jgtypelist, checkshpass } from '../../api'
import qs from 'qs'

export default {
  name: 'cityG6',
  data () {
    const _this = this
    // var checkbusiness = (rule, value, callback) => {
    //   if (!_this.funlist.business) {
    //     callback(new Error('请上传图片'))
    //   } else {
    //     callback()
    //   }
    // }
    var checkorgantype = (rule, value, callback) => {
      if (_this.p6setvalue == '') {
        callback(new Error('请选择机构类型'))
      } else {
        callback()
      }
    }
    return {
      checked: true,
      shtc: false,
      state: '',
      funlist: {
        tx: '',
        zhname: '',
        enname: '',
        der: '',
        business: '',
        contact: '',
        phone: '',
        position: '',
        side: '',
        wxid: ''
      },
      filelist: [],
      p6setoptions: [],
      p6setvalue: '',
      P6rules: {
        tx: [{ required: true, message: '请上传图片', trigger: ['blur', 'change'] }],
        zhname: [{ required: true, message: '请输入机构中文名称', trigger: 'blur' }, { min: 0, max: 24, message: '不超过24个字', trigger: 'blur' }],
        organtype: [{ required: true, validator: checkorgantype }],
        enname: [{ required: true, message: '请输入机构英文名称', trigger: 'blur' }, { min: 0, max: 140, message: 'Less than 140 characters', trigger: 'blur' }],
        der: [{ required: true, message: '请输入机构简介', trigger: 'blur' }, { min: 0, max: 220, message: '不超过220个字', trigger: 'blur' }],
        // business: [{ required: true, message: '请上传图片', validator: checkbusiness }],
        contact: [{ required: true, message: '请输入机构联系人', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入机构电话', trigger: 'blur' }],
        position: [{ required: true, message: '请输入联系人职位', trigger: 'blur' }],
        side: [{ required: true, message: '请输入机构地址', trigger: 'blur' }],
        wxid: [{ required: false, message: '请输入微信公众号ID', trigger: 'blur' }]
      }
    }
  },
  inject: ['reload'],
  watch: {
    state (newValue, oldValue) {
      if (this.state == '1') {
        this.shtc = true
      } else if (this.state == 'true') {
        this.$message({
          showClose: true,
          message: '审核通过，资料已更新。',
          type: 'success',
          center: true
        })
      } else {
        this.$message({
          showClose: true,
          message: '审核不通过，请重新申请。',
          type: 'error',
          center: true
        })
      }
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // let userInfo = {
    //   userId: '6565656'
    // }
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$router.push('/Login')
      })
    } else {
      checkshpass(userInfo.userId).then(res => {
        if (JSON.stringify(res.data) == '[]') {
          if (this.funlist.tx != '') {
            this.state = 'true'
          }
        } else {
          this.state = res.data[0].STATE
        }
      })
      listrzsp(userInfo.userId).then(res => {
        if (JSON.stringify(res.data) != '[]') {
          this.funlist = {
            tx: res.data[0].IMAGE,
            zhname: res.data[0].CNNAME,
            enname: res.data[0].ENNAME,
            der: res.data[0].SHORTCONTENT,
            contact: res.data[0].NAME,
            phone: res.data[0].PHONE,
            position: res.data[0].POSITION,
            side: res.data[0].ADDRESS,
            wxid: res.data[0].WECHATIP
          }
          this.p6setvalue = res.data[0].ONE
          // this.filelist = JSON.parse(res.data[0].PERMIT)
          // if (JSON.parse(res.data[0].PERMIT).length > 0) {
          //   this.funlist.business = true
          // } else {
          //   this.funlist.business = false
          // }
        }
      })
      jgtypelist().then(res => {
        this.p6setoptions = res.data
      })
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.funlist.tx = `/FH-WEB/${res.result}`
    },
    beforeAvatarUpload (file) {
      const isTYPE = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isTYPE) {
        this.$message({
          showClose: true,
          message: '格式错误,请上传jpg或png格式文件',
          type: 'error',
          center: true
        })
      }
      if (!isLt2M) {
        this.$message({
          showClose: true,
          message: '上传图片大小不能超过2MB!',
          type: 'error',
          center: true
        })
      }
      return isTYPE && isLt2M
    },
    handlebusinessSuccess (res, file, fileList) {
      file.url = `/FH-WEB/${res.result}`
    },
    beforebusinessUpload (file) {
      const isTYPE = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt4M = file.size / 1024 / 1024 < 4
      if (!isTYPE) {
        this.$message({
          showClose: true,
          message: '格式错误,请上传jpg或png格式文件',
          type: 'error',
          center: true
        })
      }
      if (!isLt4M) {
        this.$message({
          showClose: true,
          message: '上传图片大小不能超过4MB!',
          type: 'error',
          center: true
        })
      }
      return isLt4M && isTYPE
    },
    checkfilelist (file, filelist) {
      // if (filelist.length > 0) {
      //   this.funlist.business = true
      // } else {
      //   this.funlist.business = false
      // }
    },
    p6submit () {
      this.$confirm('资料提交后，审核期间无法更改信息，请仔细检查。', '提示', {
        confirmButtonText: '确定提交',
        cancelButtonText: '返回检查',
        type: 'warning',
        center: true
      })
        .then(() => {
          // if (!this.funlist.business) {
          //   this.$refs.p6ruleForm.clearValidate('business')
          // }
          this.$refs.p6ruleForm.validate(valid => {
            if (valid) {
              if (!this.checked) {
                this.$message({
                  showClose: true,
                  message: '请勾选《CityPlus用户注册协议》',
                  type: 'error',
                  center: true
                })
                return false
              } else {
                let userid = JSON.parse(localStorage.getItem('userInfo')).userId
                // let busimgarr = this.$refs.busupload.uploadFiles
                // console.log(busimgarr)
                // let busimglist = []
                // for (let i = 0, len = busimgarr.length; i < len; i++) {
                //   let url = {
                //     name: busimgarr[i].name,
                //     url: busimgarr[i].response == undefined ? busimgarr[i].url : `/FH-WEB/${busimgarr[i].response.result}`
                //   }
                //   busimglist.push(url)
                // }
                let list = {
                  SPONSOR_ID: userid,
                  IMAGE: this.funlist.tx,
                  CNNAME: this.funlist.zhname,
                  ENNAME: this.funlist.enname,
                  ONE: this.p6setvalue,
                  SHORTCONTENT: this.funlist.der,
                  // PERMIT: JSON.stringify(busimglist),
                  NAME: this.funlist.contact,
                  PHONE: this.funlist.phone,
                  POSITION: this.funlist.position,
                  ADDRESS: this.funlist.side,
                  WECHATIP: this.funlist.wxid
                }
                sponsoratttest(qs.stringify(list)).then(res => {
                  if (res.status == 200) {
                    this.$message({
                      showClose: true,
                      message: '上传成功,请等待审核！',
                      type: 'success',
                      center: true
                    })
                    setTimeout(() => {
                      this.reload()
                    }, 2000)
                  } else {
                    this.$message({
                      showClose: true,
                      message: '网络错误，上传失败，请刷新页面后重试。',
                      type: 'error',
                      center: true
                    })
                  }
                })
              }
            }
          })
        })
        .catch(() => {})
    },
    overLimit () {
      // this.$message({
      //   showClose: true,
      //   message: '上传数量已上限，如需更改，请点击已上传图片右上角进行删除后上传新图片！',
      //   type: 'error',
      //   center: true
      // })
      this.$confirm('已超过上传数量，如需更改，请点击已上传图片右上角进行删除，重新上传图片。', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {})
    }
  }
}
</script>

<style lang="stylus" scoped src="../../assets/css/CityG.styl">

</style>
<style scoped lang="stylus">
.G-container.zh {
  .content-box {
    .main-p{
      &.main-p6{
        position relative
        .mask{
          position absolute
          width 100%
          height 100%
          background #fff
          top 0
          left 0
          display flex
          justify-content center
          padding-top .8rem
          font-size 0.3444rem
          color #000
          z-index 888
        }
        .p6-form{
          .business-tip{
            color #8d8d8d
            line-height 1.4
            position absolute
            top 2.32rem
            left 7.5rem
            padding 0
          }
          .el-upload__text{
            color #8d8d8d
          }
        }
      }
    }
  }
}
@media screen and (max-width: 990px){
  .G-container.zh {
    .content-box {
      .main-p{
        &.main-p6{
          padding-bottom .3rem
          .p6-der{
            color #8d8d8d
            font-size 0.3333rem
          }
          .p6-form{
            .tx-upload-tip{
              width 3.6rem
              line-height 1.4
              top .75rem
              left 2.5rem
              .el-form-item__content{
                width 100%
                margin-left 0 !important
              }
            }
            .tx-upload{
              display flex
            }
            .p6-item-fra{
              .el-form-item{
                &:nth-child(1){
                  width 100%
                }
                &:nth-child(2){
                  width 100%
                  .el-form-item__label{
                    text-align left
                  }
                }
              }
            }
            .el-upload__text{
              font-size 0.3333rem
            }
            .business-tip{
              color #8d8d8d
              line-height 1.4
              position unset
              padding .2rem 0 0 0
            }
            .el-form-item{
              margin-bottom .2rem
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="stylus">
@media screen and (max-width: 990px){
  .G-container.zh {
    .content-box{
      .content{
       .main-p{
         &.main-p6{
           .p6-form{
             .el-form-item__content{
               width 100%
               margin-left 0 !important
             }
             .el-upload.el-upload--picture{
               width 100%
             }
             .el-upload-dragger{
               width 100%
             }
             .p6-select{
               .el-input__suffix{
                 padding 1rem 0 0 0
               }
             }
             .el-form-item__label{
               width 2.888rem !important
             }
             .p6-item-fra{
               .el-form-item{
                 &:nth-child(2){
                   .el-form-item__label{
                     text-align left
                   }
                 }
               }
             }
           }
           .form-sub{
             .form-sub-btn{
               margin-top 1rem
               display block
               font-size 0.4074rem
             }
             .check{
               padding-bottom .4rem
             }
           }
         }
       }
      }
    }
  }
}
</style>
