<template>
  <div>
    <div class="main-p main-p2" v-show="true">
      <p class="tit hidden-sm-and-down">参与的活动</p>
      <ul class="main-tab">
        <li :class="cur == 0 ? 'active' : ''" @click="changecur(0, '')">
          全部
          <span>{{ joinlist2.length }}</span>
        </li>
        <li :class="cur == 1 ? 'active' : ''" @click="changecur(1, '未验票')">
          未验票
          <span>{{ getlen('未验票') }}</span>
        </li>
        <li :class="cur == 2 ? 'active' : ''" @click="changecur(2, '已验票')">
          已验票
          <span>{{ getlen('已验票') }}</span>
        </li>
        <li :class="cur == 3 ? 'active' : ''" @click="changecur(3, '已结束')">
          已结束
          <span>{{ getstopjoinlen() }}</span>
        </li>
        <!-- <li :class="cur == 4 ? 'active' : ''" @click="changecur(4, '已取消')">
          已取消
          <span>{{ getlen('已取消') }}</span>
        </li> -->
      </ul>
      <div class="hidden-sm-and-down">
        <div class="fun-lists">
          <div class="fun-list" v-for="(item, index) in joinlist.slice((pagecur - 1) * pagecnt, pagecur * pagecnt)" :key="index">
            <div>
              <el-row class="fun-list-cnt">
                <el-col :xs="6" :sm="6" :md="6">
                  <div class="code-pic"><img :src="item.ONE" @click="goactive(item)" /></div>
                </el-col>
                <el-col :xs="18" :sm="18" :md="18">
                  <div class="fun-cnts">
                    <p class="cnts-tit" @click="goactive(item)">{{ item.TITLE }}</p>
                    <div class="cnts-lists">
                      <div class="cnts-list">
                        <p class="cnt-text">
                          <span>报名时间</span>
                          {{ gettime(item.STARTTIME2) }}
                        </p>
                        <p class="cnt-text">
                          <span>票码</span>
                          {{ (!item.stopjoin && item.A == '1') || (!item.stopjoin && item.A == '4') ? item.C : '-' }}
                        </p>
                      </div>
                      <div class="cnts-list">
                        <p class="cnt-text">
                          <span>活动地点</span>
                          {{ item.ADDRESS }}
                        </p>
                        <p class="cnt-text">
                          <span>票类</span>
                          免费票
                        </p>
                      </div>
                      <div class="cnts-list">
                        <p class="cnt-text">
                          <span>活动时间</span>
                          {{ gettime(item.STARTTIME) }}-{{ gettime(item.ENDTIME) }}
                        </p>
                        <p class="cnt-text">
                          <span>状态</span>
                          <span :class="item.stopjoin ? 'stop-inspect' : gettype(item.A, item).num == 1 ? 'no-inspect' : (gettype(item.A,item).num == 4?'inspect-fail':'inspect')">
                            {{ item.stopjoin ? '已结束' : gettype(item.A, item).text }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <ul class="operate-list" v-show="(!item.stopjoin && gettype(item.A, item).num == 1) || (!item.stopjoin && gettype(item.A, item).num == 2)">
                <li>
                  <div>
                    <i class="el-icon-s-tools"></i>
                    操作区
                  </div>
                </li>
                <li>
                  <div v-clipboard:copy="`${href}?id=${item.MAKEACTIVITY_ID}&uid=${item.USERID}`" v-clipboard:success="onCopy" v-clipboard:error="onError">
                    <i class="el-icon-link"></i>
                    复制链接
                  </div>
                </li>
                <li>
                  <el-popover placement="top-start" title="您的入场码" width="200" trigger="hover">
                    <div>
                      <div :id="'qr-' + index" class="codeqr" :ref="'qr-' + index"></div>
                      <p>{{ item.C }}</p>
                    </div>
                    <el-button slot="reference">
                      <i class="el-icon-qr"></i>
                      显示入场码
                    </el-button>
                  </el-popover>
                  <!-- <div>
                    <div class="codetc">
                      <div :id="'qr-' + index"></div>
                      <p>{{ item.C }}</p>
                    </div>
                    <i class="el-icon-qr"></i>
                    显示入场码
                  </div> -->
                </li>
                <li @click="stopjoin(item)">
                  <div>
                    <i class="el-icon-remove"></i>
                    取消参与
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <el-pagination
          hide-on-single-page
          background
          layout="prev, pager, next"
          :total="joinlist.length"
          :page-size="pagecnt"
          :current-page="pagecur"
          @prev-click="prev()"
          @next-click="next()"
          @current-change="changepage"
        ></el-pagination>
      </div>

      <div class="fun-lists-m hidden-md-and-up">
        <ul>
          <li v-for="(item, index) in joinlist" :key="index">
            <el-row type="flex">
              <el-col :span="8" class="code-pic-m"><img :src="item.ONE" @click="goactive(item)" /></el-col>
              <el-col :span="16" class="cnts-list">
                <div class="cnts-tit" @click="goactive(item)">
                  <p>{{ item.TITLE }}</p>
                </div>
                <p class="cnts1-m">
                  活动时间
                  <span>{{ gettime(item.STARTTIME) }}-{{ gettime(item.ENDTIME) }}</span>
                </p>
                <el-row type="flex">
                  <el-col :span="10">
                    <p class="cnts2-m">
                      票类
                      <span>免费票</span>
                    </p>
                    <p class="cnts2-m">
                      状态
                      <span
                        :class="item.stopjoin ? 'stop-inspect' : gettype(item.A, item).num == 1 ? 'no-inspect' : gettype(item.A, item).num == 2 ? 'inspect' : 'stop-inspect'"
                      >
                        {{ item.stopjoin ? '已结束' : gettype(item.A, item).text }}
                      </span>
                    </p>
                  </el-col>
                  <el-col :span="14" class="cnts-btn">
                    <div class="lookqr-btn" @click="p2checkqr(item)" v-if="gettype(item.A, item).num == 1 || gettype(item.A, item).num == 2">查看入场码</div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </li>
          <!-- <li>
            <el-row type="flex">
              <el-col :span="8" class="code-pic-m"><img src="../../assets/images/P/code.png" /></el-col>
              <el-col :span="16" class="cnts-list">
                <div class="cnts-tit"><p>阳光有氧双人瑜伽之旅之旅阳光有氧双人瑜伽之旅之旅</p></div>
                <p class="cnts1-m">
                  活动时间
                  <span>03月01日 09:00-04月05日 09:00</span>
                </p>
                <el-row type="flex">
                  <el-col :span="10">
                    <p class="cnts2-m">
                      票类
                      <span>免费票</span>
                    </p>
                    <p class="cnts2-m">
                      状态
                      <span class="no-inspect">已验票</span>
                    </p>
                  </el-col>
                  <el-col :span="14" class="cnts-btn"><div class="lookqr-btn" @click="p2checkqr()">查看入场码</div></el-col>
                </el-row>
              </el-col>
            </el-row>
          </li>
          <li>
            <el-row type="flex">
              <el-col :span="8" class="code-pic-m"><img src="../../assets/images/P/code.png" /></el-col>
              <el-col :span="16" class="cnts-list">
                <div class="cnts-tit"><p>阳光有氧双人瑜伽之旅之旅阳光有氧双人瑜伽之旅之旅</p></div>
                <p class="cnts1-m">
                  活动时间
                  <span>03月01日 09:00-04月05日 09:00</span>
                </p>
                <el-row type="flex">
                  <el-col :span="10">
                    <p class="cnts2-m">
                      票类
                      <span>免费票</span>
                    </p>
                    <p class="cnts2-m">
                      状态
                      <span class="stop-inspect">已结束</span>
                    </p>
                  </el-col>
                  <el-col :span="14" class="cnts-btn"><div class="lookqr-btn" @click="p2checkqr()">查看入场码</div></el-col>
                </el-row>
              </el-col>
            </el-row>
          </li>
          <li>
            <el-row type="flex">
              <el-col :span="8" class="code-pic-m"><img src="../../assets/images/P/code.png" /></el-col>
              <el-col :span="16" class="cnts-list">
                <div class="cnts-tit"><p>阳光有氧双人瑜伽之旅之旅阳光有氧双人瑜伽之旅之旅</p></div>
                <p class="cnts1-m">
                  活动时间
                  <span>03月01日 09:00-04月05日 09:00</span>
                </p>
                <el-row type="flex">
                  <el-col :span="10">
                    <p class="cnts2-m">
                      票类
                      <span>免费票</span>
                    </p>
                    <p class="cnts2-m">
                      状态
                      <span class="stop-inspect">已取消</span>
                    </p>
                  </el-col>
                  <el-col :span="14" class="cnts-btn"><div class="lookqr-btn" @click="p2checkqr()">查看入场码</div></el-col>
                </el-row>
              </el-col>
            </el-row>
          </li> -->
        </ul>
      </div>
      <div class="lookqr-tc" v-show="p2qrder">
        <div class="tc-top">
          <el-row type="flex">
            <el-col :span="20">
              <p class="tit-m">{{ lookitem.TITLE }}</p>
            </el-col>
            <el-col :span="4" class="close-btn">
              <div class="close" @click="p2qrder = false">
                关闭
                <i class="el-icon-close"></i>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="tc-mid">
          <p>
            活动时间
            <span>{{ gettime(lookitem.STARTTIME2) }}-{{ gettime(lookitem.ENDTIME2) }}</span>
          </p>
          <p>
            活动地点
            <span>{{ lookitem.ADDRESS }}</span>
            <!-- <span class="look-map">查看地点</span> -->
          </p>
        </div>
        <div class="tc-bom">
          <div class="qr" id="phoneqr"></div>
          <p>
            票码
            <span>{{ lookitem.C }}</span>
          </p>
          <div class="bom-cnts">
            <p>
              姓名
              <span>{{ lookitem.NAME }}</span>
            </p>
            <p>
              电话
              <span>{{ lookitem.CELLPHONE }}</span>
            </p>
            <p>
              报名时间
              <span>{{ gettime(lookitem.STARTTIME) }}</span>
            </p>
            <p>
              状态
              <span
                :class="
                  lookitem.stopjoin
                    ? 'stop-inspect'
                    : gettype(lookitem.A, lookitem).num == 1
                    ? 'no-inspect'
                    : gettype(lookitem.A, lookitem).num == 2
                    ? 'inspect'
                    : 'stop-inspect'
                "
              >
                {{ lookitem.stopjoin ? '已结束' : gettype(lookitem.NUM, lookitem).text }}
              </span>
            </p>
          </div>
        </div>
        <div class="bom-btn" @click="stopjoin(item)">
          <i class="el-icon-remove"></i>
          取消参与
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import { joinfunlist, stopjoin } from '../../api'
import qs from 'qs'
export default {
  name: 'cityG2',
  data () {
    return {
      pagecur: 1,
      pagecnt: 10,
      p2qrder: false,
      joinlist: [],
      joinlist2: [],
      cur: 0,
      curtext: '',
      list1: [],
      list2: [],
      list3: [],
      list4: [],
      lookitem: [],
      href: window.location.protocol + '//' + window.location.host + window.location.pathname + '#/activity'
    }
  },
  inject: ['reload'],
  mounted () {
    this.$nextTick(() => {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
        this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/Login')
        })
      } else {
        // let name = 'hbh';
        let id = userInfo.userId
        joinfunlist(id).then(res => {
          let list = []
          for (let i = 0, len = res.data.length; i < len; i++) {
            let newtime = new Date().valueOf()
            let end = new Date(res.data[i].ENDTIME).valueOf()
            if (newtime > end) {
              res.data[i].stopjoin = true
            } else {
              res.data[i].stopjoin = false
            }
            if (res.data[i].EXAMINE == 'false') {
              list.push(res.data[i])
            } else if (res.data[i].EXAMINE == 'true'
             // && res.data[i].A == '1'
             ) {
              list.push(res.data[i])
            }
          }
          this.joinlist = list
          this.joinlist2 = list
          setTimeout(() => {
            $('.codeqr').html('')
            let datas = this.joinlist.slice((this.pagecur - 1) * this.pagecnt, this.pagecur * this.pagecnt)
            for (let i = 0, len = datas.length; i < len; i++) {
              let href = window.location.protocol + '//' + window.location.host + window.location.pathname + '#/code?code=' + datas[i].C
              let id = 'qr-' + i
              this.getcode(id, href)
            }
          }, 200)
        })
      }
    })
  },
  methods: {
    getcode (id, h) {
      new QRCode(id, {
        width: 150, // 设置宽度，单位像素
        height: 150, // 设置高度，单位像素
        text: h // 设置二维码内容或跳转地址
      })
    },
    gettime (date) {
      var newdate = new Date(date)
      var year = newdate.getFullYear()
      var month = newdate.getMonth() + 1
      var day = newdate.getDate()
      var hour = newdate.getHours() < 10 ? '0' + newdate.getHours() : newdate.getHours()
      var minute = newdate.getMinutes() < 10 ? '0' + newdate.getMinutes() : newdate.getMinutes()
      var currentTime = year + '年' + month + '月' + day + '日  ' + hour + ':' + minute
      return currentTime
    },
    changecur (num, str) {
      this.pagecur = 1
      this.cur = num
      this.curtext = str
      let list = []
      if (num == 0) {
        list = this.joinlist2
      } else if (num == 1) {
        for (let i = 0, len = this.joinlist2.length; i < len; i++) {
          if (this.joinlist2[i].A == '1') {
            list.push(this.joinlist2[i])
          }
        }
      } else if (num == 2) {
        for (let i = 0, len = this.joinlist2.length; i < len; i++) {
          if (this.joinlist2[i].A == '4') {
            list.push(this.joinlist2[i])
          }
        }
      } else if (num == 4) {
        for (let i = 0, len = this.joinlist2.length; i < len; i++) {
          if (this.joinlist2[i].A == '3') {
            list.push(this.joinlist2[i])
          }
        }
      } else if (num == 3) {
        for (let i = 0, len = this.joinlist2.length; i < len; i++) {
          if (this.joinlist2[i].stopjoin) {
            list.push(this.joinlist2[i])
          }
        }
      }
      this.joinlist = list
      setTimeout(() => {
        $('.codeqr').html('')
        let datas = this.joinlist.slice((this.pagecur - 1) * this.pagecnt, this.pagecur * this.pagecnt)
        for (let i = 0, len = datas.length; i < len; i++) {
          let href = window.location.protocol + '//' + window.location.host + window.location.pathname + '#/code?code=' + datas[i].C
          let id = 'qr-' + i
          this.getcode(id, href)
        }
      }, 200)
    },
    p2checkqr (item) {
      this.p2qrder = true
      this.lookitem = item
      $('#phoneqr').html('')
      let href = window.location.protocol + '//' + window.location.host + '/#/code?code=' + item.C
      this.getcode('phoneqr', href)
    },
    gettype (num = '1', item = []) {
      let type
      if (num == '1' || num == '6') {
        type = {
          num: 1,
          text: '未验票'
        }
      } else if (num == '4') {
        type = {
          num: 2,
          text: '已验票'
        }
      } else if (num == '2') {
        type = {
          num: 6,
          text: '已取消'
        }
      } else if (num == '3') {
        type = {
          num: 4,
          text: '审核不通过'
        }
      } else if (num == '5') {
        type = {
          num: 5,
          text: '审核中'
        }
      }
      return type
    },
    getlen (str) {
      let list = []
      let len = this.joinlist2
      for (let i = 0, len2 = len.length; i < len2; i++) {
        if (this.gettype(this.joinlist2[i].A, this.joinlist2[i]).text == str) {
          list.push(this.joinlist2[i])
        }
      }
      return list.length
    },
    getstopjoinlen () {
      let list = []
      for (let i = 0, len = this.joinlist2.length; i < len; i++) {
        if (this.joinlist2[i].stopjoin) {
          list.push(this.joinlist2[i])
        }
      }
      return list.length
    },
    prev () {
      this.pagecur--
      setTimeout(() => {
        let datas = this.joinlist.slice((this.pagecur - 1) * this.pagecnt, this.pagecur * this.pagecnt)
        $('.codeqr').html('')
        for (let i = 0, len = datas.length; i < len; i++) {
          let href = window.location.protocol + '//' + window.location.host + window.location.pathname + '#/code?code=' + datas[i].C
          let id = 'qr-' + i
          this.getcode(id, href)
        }
      }, 200)
    },
    next () {
      this.pagecur++
      setTimeout(() => {
        let datas = this.joinlist.slice((this.pagecur - 1) * this.pagecnt, this.pagecur * this.pagecnt)
        $('.codeqr').html('')
        for (let i = 0, len = datas.length; i < len; i++) {
          let href = window.location.protocol + '//' + window.location.host + window.location.pathname + '#/code?code=' + datas[i].C
          let id = 'qr-' + i
          this.getcode(id, href)
        }
      }, 200)
    },
    onCopy () {
      this.$message({
        showClose: true,
        message: '复制成功',
        type: 'success',
        center: true
      })
    },
    onError () {
      this.$message({
        showClose: true,
        message: '复制失败',
        type: 'error',
        center: true
      })
    },
    stopjoin (item) {
      this.$confirm('此操作将取消参与该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = item.MAKEACTIVITYMX_ID
          let list = {
            MAKEACTIVITYMX_ID: id
          }
          stopjoin(qs.stringify(list)).then(res => {
            if (res.data.result == 'ok') {
              this.$message({
                type: 'success',
                message: '取消参与成功！',
                center: true
              })
              this.reload()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作！',
            center: true
          })
        })
    },
    goactive (item) {
      let id = item.MAKEACTIVITY_ID
      let uid = item.USERID
      this.$router.push({
        name: 'cityQ5',
        query: {
          id: id,
          uid: uid
        }
      })
    },
    changepage(e){
      this.pagecur = e
      setTimeout(() => {
        let datas = this.joinlist.slice((this.pagecur - 1) * this.pagecnt, this.pagecur * this.pagecnt)
        $('.codeqr').html('')
        for (let i = 0, len = datas.length; i < len; i++) {
          let href = window.location.protocol + '//' + window.location.host + window.location.pathname + '#/code?code=' + datas[i].C
          let id = 'qr-' + i
          this.getcode(id, href)
        }
      }, 300)
    }
  }
}
</script>

<style lang="stylus" scoped src="../../assets/css/CityG.styl">

</style>
<style lang="stylus">
  .el-popover{
    text-align center
  }
  .codeqr{
    img{
      display block
      margin 0 auto .2rem
    }
  }
.main-p2{
  #phoneqr{
    img{
      width 100%
    }
  }
  .el-button{
    border none
    padding 0.1rem 0
    display block
    border-right 0.0185rem solid #c6c6c6
    border-radius 0
    font-size 0.2592rem
    color #8d8d8d
    line-height inherit
    width 100%
    &:hover{
      background #fff
    }
  }
}
</style>
