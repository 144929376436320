<template>
  <div>
    <div class="main-p main-p3" v-show="true">
      <p class="tit hidden-sm-and-down">发起的活动</p>
      <ul class="main-tab">
        <li :class="cur == 0 ? 'active' : ''" @click="changecur(0)">
          全部
          <span>{{ initiatelist2.length }}</span>
        </li>
        <li :class="cur == 1 ? 'active' : ''" @click="changecur(1)">
          开放报名中
          <span>{{morelist.list1.length}}</span>
        </li>
        <!-- <li :class="cur == 2 ? 'active' : ''" @click="changecur(2)">
          审核中
          <span>{{morelist.list2.length}}</span>
        </li> -->
        <li :class="cur == 3 ? 'active' : ''" @click="changecur(3)">
          已结束
          <span>{{morelist.list3.length}}</span>
        </li>
        <!-- <li :class="cur == 4 ? 'active' : ''" @click="changecur(4)">
          编辑中
          <span>0</span>
        </li> -->
      </ul>
      <div class="hidden-sm-and-down">
        <div class="fun-lists">
          <div class="fun-list" v-for="(item, index) in initiatelist.slice((pagecur - 1) * pagecnt, pagecur * pagecnt)" :key="index">
            <el-row class="fun-list-cnt">
              <el-col :xs="6" :sm="6" :md="6">
                <div class="code-pic" @click="goactive(item)" style="cursor: pointer;"><img :src="item.ONE" /></div>
              </el-col>
              <el-col :xs="18" :sm="18" :md="18">
                <div class="fun-cnts">
                  <p class="cnts-tit" @click="goactive(item)" style="cursor: pointer;">{{ item.TITLE }}</p>
                  <div class="cnts-lists">
                    <div class="cnts-list">
                      <p class="cnt-text">
                        <span>创建时间</span>
                        {{ gettime(item.CTIME) }}
                      </p>
                      <p class="cnt-text">
                        <span>已报名</span>
                        {{item.len1 || 0}}/{{item.NUMBER || 0}}
                      </p>
                    </div>
                    <div class="cnts-list">
                      <p class="cnt-text">
                        <span>活动地点</span>
                        {{ item.ADDRESS }}
                      </p>
                      <p class="cnt-text">
                        <span>已验票</span>
                        {{item.len2 || 0}}/{{item.len1 || 0}}
                      </p>
                    </div>
                    <div class="cnts-list">
                      <p class="cnt-text">
                        <span>活动时间</span>
                        {{ gettime(item.STARTTIME) }}-{{ gettime(item.ENDTIME) }}
                      </p>
                      <p class="cnt-text">
                        <span>&nbsp;&nbsp;&nbsp;状态</span>
                        <span :class="getstatus(item.STARTTIME,item.ENDTIME).type == 2?'stop-inspect':(item.STATE == 'true' && getstatus(item.STARTTIME,item.ENDTIME).type != 2?'no-inspect':(item.STATE == '1'?'inspect':'inspect-fail'))">
                        {{getstatus(item.STARTTIME,item.ENDTIME).type == 2?'已结束':(item.STATE == 'true' && getstatus(item.STARTTIME,item.ENDTIME).type != 2?'开放报名中':(item.STATE == '1'?'审核中':'审核不通过'))}}
                        <i class="el-icon-question tip" v-if="item.STATE == 'false'">
                          <div class="tip-tc">{{item.TAG}}</div>
                        </i>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <ul class="operate-list" v-if="item.STATE == 'true'">
              <li>
                <div>
                  <i class="el-icon-s-tools"></i>
                  操作区
                </div>
              </li>
              <li>
                <div @click="gocreate(item)">
                  <i class="el-icon-edit-outline"></i>
                  编辑活动
                </div>
              </li>
              <li @click="ticket(item)">
                <div>
                  <i class="el-icon-success"></i>
                  签到验票
                </div>
              </li>
              <li @click="roster(item)">
                <div>
                  <i class="el-icon-s-order"></i>
                  名单管理
                </div>
              </li>
              <li @click="stopjoin(item)">
                <div>
                  <i class="el-icon-remove"></i>
                  取消活动
                </div>
              </li>
            </ul>
          </div>
        </div>
        <el-pagination
          hide-on-single-page
          background
          layout="prev, pager, next"
          :total="initiatelist.length"
          :page-size="pagecnt"
          :current-page="pagecur"
          @prev-click="prev()"
          @next-click="next()"
          @current-change="changepage"
        ></el-pagination>
      </div>

      <div class="fun-lists-m hidden-md-and-up">
        <p class="m-tit">编辑活动，名单管理，设置扫码验票请登录PC端网站操作</p>
        <ul>
          <li v-for="(item, index) in initiatelist" :key="index">
            <el-row type="flex">
              <el-col :span="8" class="code-pic-m"><img :src="item.ONE" @click="goactive(item)" /></el-col>
              <el-col :span="16" class="cnts-list">
                <div class="cnts-tit" @click="goactive(item)">
                  <p>{{ item.TITLE }}</p>
                </div>
                <p class="cnts1-m">
                  活动时间
                  <span>{{ gettime(item.STARTTIME) }}-{{ gettime(item.ENDTIME) }}</span>
                </p>
                <p class="cnts2-m">
                  已报名
                  <span>{{item.len1 || 0}}/{{item.NUMBER || 0}}</span>
                </p>
                <p class="cnts2-m">
                  已验票
                  <span>{{item.len2 || 0}}/{{item.len1 || 0}}</span>
                </p>
                <p class="cnts2-m">
                  状态
                  <span :class="getstatus(item.STARTTIME,item.ENDTIME).type == 2?'stop-inspect':(item.STATE == 'true' && getstatus(item.STARTTIME,item.ENDTIME).type != 2?'inspect':(item.STATE == '1'?'no-inspect':'fail-inspect'))">
                    {{getstatus(item.STARTTIME,item.ENDTIME).type == 2?'已结束':(item.STATE == 'true' && getstatus(item.STARTTIME,item.ENDTIME).type != 2?'开放报名中':(item.STATE == '1'?'审核中':'审核不通过'))}}
                    <i class="el-icon-question tip" v-if="item.STATE == 'false'" @click="looktc(item.TAG)" style="vertical-align: bottom;margin: 0 0 0 .1rem;"></i>
                  </span>
                </p>
                <el-row type="flex">
                  <el-col :span="24" class="cnts-btn"><div class="lookqr-btn" @click="p3gosign()">签到验票</div></el-col>
                </el-row>
              </el-col>
            </el-row>
          </li>
        </ul>
      </div>
      <div class="mask" v-show="mask"></div>
      <div class="fail-tc" v-show="failtc">
        <p class="fail-tit">不通过审核原因</p>
        <div class="fail-fra">
          <p class="fail-text">{{ failText }}</p>
        </div>
        <div
          class="close"
          @click="
            mask = false;
            failtc = false;
          "
        >
          关闭
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { initiatelist, CancelActive, getactivesppxs } from '../../api'
import qs from 'qs'
export default {
  name: 'cityG3',
  inject: ['reload'],
  data () {
    return {
      pagecur: 1,
      pagecnt: 10,
      mask: false,
      failtc: false,
      initiatelist: [],
      initiatelist2: [],
      cur: 0,
      curtext: '',
      people: [],
      morelist: {
        list1: [],
        list2: [],
        list3: []
      },
      failText: ''
    }
  },
  mounted () {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        this.$router.push('/Login')
      })
    } else {
      // let id2 = '592055c3510b42c999fcd9d238fdf768'
      let id2 = userInfo.userId
      initiatelist(id2).then(res => {
        this.initiatelist = res.data
        this.initiatelist2 = res.data
        for (let i = 0, len = res.data.length; i < len; i++) {
          let id = res.data[i].MAKEACTIVITY_ID
          getactivesppxs(id).then(res => {
            let list = []
            let list2 = []
            for (let _i = 0, len = res.data.length; _i < len; _i++) {
              if (res.data[_i].A == '1') {
                list.push(res.data[_i])
              }
              if (res.data[_i].A == '4') {
                list2.push(res.data[i])
              }
            }
            // this.initiatelist[i].len1 = list.length
            // this.initiatelist[i].len2 = list2.length
            // let list1 = {
            //   len1: list.length,
            //   len2: list2.length
            // }
            this.$set(this.initiatelist[i], 'len1', list.length)
            this.$set(this.initiatelist[i], 'len2', list2.length)
            this.$set(this.initiatelist2[i], 'len1', list.length)
            this.$set(this.initiatelist2[i], 'len2', list2.length)
            let morelist1 = []
            let morelist2 = []
            let morelist3 = []
            for (let i = 0, len = this.initiatelist2.length; i < len; i++) {
              if (this.initiatelist2[i].STATE == 'true' && this.getstatus(this.initiatelist2[i].STARTTIME2, this.initiatelist2[i].ENDTIME2).type != 2) {
                morelist1.push(this.initiatelist2[i])
              }
            }
            for (let i = 0, len = this.initiatelist2.length; i < len; i++) {
              if (this.initiatelist2[i].STATE == '1') {
                morelist2.push(this.initiatelist2[i])
              }
            }
            for (let i = 0, len = this.initiatelist2.length; i < len; i++) {
              if (this.getstatus(this.initiatelist2[i].STARTTIME, this.initiatelist2[i].ENDTIME).type == 2) {
                morelist3.push(this.initiatelist2[i])
              }
            }
            this.morelist.list1 = morelist1
            this.morelist.list2 = morelist2
            this.morelist.list3 = morelist3
          })
        }
      })
    }
  },
  methods: {
    roster (item) {
      this.$router.push({
        name: 'cityG2b',
        params: {
          item: item
        }
      })
    },
    ticket (item) {
      this.$router.push({
        name: 'cityG2A',
        params: {
          item: item
        }
      })
    },
    p3gosign () {
      this.$router.push('/Code')
    },
    looktc (text) {
      this.failText = text
      this.mask = true
      this.failtc = true
    },
    changecur (num) {
      this.pagecur = 1
      this.cur = num
      if (num == 0) {
        console.log('全部')
        this.initiatelist = this.initiatelist2
      } else if (num == 1) {
        console.log('开放报名')
        let list = []
        // for(let i = 0,len = this.initiatelist2.length;i<len;i++){
        //   if(this.initiatelist2[i].STATE == 'true' && this.getstatus(this.initiatelist2[i].STARTTIME,this.initiatelist2[i].ENDTIME).type == 1){
        //     list.push(this.initiatelist2[i])
        //   }
        // }
        // this.initiatelist = list
        // this.lenlist.len1 = list
        this.initiatelist = this.morelist.list1
      } else if (num == 2) {
        console.log('审核中')
        let list = []
        // for(let i = 0,len = this.initiatelist2.length;i<len;i++){
        //   if(this.initiatelist2[i].STATE == '1'){
        //     list.push(this.initiatelist2[i])
        //   }
        // }
        // this.initiatelist = list
        this.initiatelist = this.morelist.list2
      } else if (num == 3) {
        console.log('已结束')
        let list = []
        // for(let i = 0,len = this.initiatelist2.length;i<len;i++){
        //   if(this.getstatus(this.initiatelist2[i].STARTTIME,this.initiatelist2[i].ENDTIME).type == 2){
        //     list.push(this.initiatelist2[i])
        //   }
        // }
        // this.initiatelist = list
        this.initiatelist = this.morelist.list3
      }
    },
    gettime (date) {
      var newdate = new Date(date)
      var year = newdate.getFullYear()
      var month = newdate.getMonth() + 1
      var day = newdate.getDate()
      var hour = newdate.getHours() < 10 ? '0' + newdate.getHours() : newdate.getHours()
      var minute = newdate.getMinutes() < 10 ? '0' + newdate.getMinutes() : newdate.getMinutes()
      var currentTime = year + '年' + month + '月' + day + '日  ' + hour + ':' + minute
      return currentTime
    },
    prev () {
      this.pagecur--
    },
    next () {
      this.pagecur++
    },
    gocreate (item) {
      // this.$confirm('此操作将跳转至该活动详情页，如要修改活动内容，活动将会重新审核，是否继续?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
        let id = item.MAKEACTIVITY_ID
        this.$router.push({
          name: 'cityQ6',
          query: {
            id: id
          }
        })
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消操作！',
      //     center: true
      //   })
      // })
    },
    stopjoin (item) {
      this.$confirm('此操作将取消该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let id = item.MAKEACTIVITY_ID
        let list = {
          MAKEACTIVITY_ID: id
        }
        CancelActive(qs.stringify(list)).then(res => {
          if (res.data.result == 'ok') {
            this.$message({
              type: 'success',
              message: '取消成功！',
              center: true
            })
            this.reload()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作！',
          center: true
        })
      })
    },
    getstatus (starttime, endtime) {
      let newtime = new Date().valueOf()
      let start = new Date(starttime).valueOf()
      let end = new Date(endtime).valueOf()
      let status
      if (newtime >= start && newtime <= end) {
        status = {
          type: 1,
          text: '开放报名'
        }
      } else if (newtime > end) {
        status = {
          type: 2,
          text: '已结束'
        }
      } else if (newtime < start) {
        status = {
          type: 3,
          text: '开放报名'
        }
      }
      return status
    },
    goactive(item){
      let id = item.MAKEACTIVITY_ID
      let uid = item.USERID
      this.$router.push({
        name:'cityQ5',
        query:{
          id :id,
          uid :uid
        }
      })
    },
    changepage(e){
      this.pagecur = e
    }
  },
  watch: {
    initiatelist (newValue, oldValue) {}
  }
}
</script>

<style lang="stylus" scoped src="../../assets/css/CityG.styl">

</style>
<style scoped lang="stylus">
@media screen and (max-width: 990px){
  .G-container.zh {
    .main-p{
      &.main-p3{
        .main-tab{
          font-size 0.2962rem
          li{
            width 25%
          }
        }
      .fun-lists-m{
        .m-tit{
          color #8d8d8d
          font-size 0.3333rem
          text-align center
          padding .2rem 0
          border-bottom 0.0185rem solid #e2e2e2
        }
        li{
          padding .4rem 0
          border-bottom 0.0185rem solid #e2e2e2
          .code-pic-m{
              display flex
              align-items baseline
              img{
                width 100%
                display block
              }
            }
            .cnts-list{
              padding 0 0 0 .2rem
              .cnts-tit{
                display flex
                height .8rem
                align-items center
                p{
                  color #474747
                  font-size 0.3333rem
                  position relative
                  word-break break-all
                  padding 0 0 0 .4rem
                  line-height 1
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  overflow: hidden;
                  &::before{
                    content ''
                    border-left 0.0555rem solid #a5cd39
                    height 100%
                    left 0
                    top 0
                    position absolute
                  }
                }
              }
              .cnts1-m,
              .cnts2-m{
                font-size 0.2962rem
                color #a5cd39
                line-height 1
                padding .2rem 0 0 0
                word-break break-all
                span{
                  color #8d8d8d
                  margin 0 0 0 .2rem
                  &.inspect{
                    background #ffa300
                    color #fff
                    padding 0 .1rem
                    margin 0 0 0 .45rem
                  }
                  &.no-inspect{
                    color #ffa300
                    margin 0 0 0 .45rem
                  }
                  &.stop-inspect{
                    color #8d8d8d
                    margin 0 0 0 .45rem
                  }
                  &.fail-inspect{
                    color #ff0000
                    margin 0 0 0 .45rem
                  }
                  i{
                    color #c8c8c8
                    font-size 0.3703rem
                    display inline-block
                    margin 0 0 0 .2rem
                    cursor pointer
                  }
                }
              }
              .cnts1-m{
                display flex
                span{
                  width 76%
                }
              }
              .cnts-btn{
                display flex
                justify-content end
                align-items flex-end
                padding .4rem 0 0 0
                .lookqr-btn{
                  background #a5cd39
                  color #fff
                  font-size 0.3333rem
                  display inline-block
                  padding 0.1rem 0.7rem
                }
              }
            }
          }
        }
        .mask{
          position fixed
          width 100%
          height 100%
          background rgba(0,0,0,.5)
          top 0
          left 0
          z-index 999999
        }
        .fail-tc{
          background #fff
          position fixed
          top 50%
          left 50%
          transform translate(-50%,-50%)
          z-index 999999
          font-size 0.3888rem
          padding .6rem .4rem
          color #000
          min-width 6rem
          text-align center
          .fail-tit{
            padding .2rem 0
            border-bottom 0.0185rem solid #d3d3d3
            text-align left
          }
          .fail-fra{
            padding .4rem 0
            text-align left
            .fail-text{

            }
          }
          .close{
            color #fff
            background #a5cd39
            padding .15rem 1.4rem
            display inline-block
            cursor pointer
          }
        }
      }
    }
  }
}
</style>
