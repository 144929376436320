<template>
  <div>
    <div class="main-p main-p4" v-show="true">
      <p class="tit hidden-sm-and-down">收藏的活动</p>
      <ul class="main-tab">
        <li :class="cur == 0 ? 'active' : ''" @click="changecur(0, '')">
          全部
          <span>{{ colfunlist == undefined ? 0 : colfunlist.length }}</span>
        </li>
        <li :class="cur == 1 ? 'active' : ''" @click="changecur(1, '开放报名')">
          进行中
          <span>{{ colfunlist == undefined ? 0 : getfunnum('开放报名') }}</span>
        </li>
        <li :class="cur == 2 ? 'active' : ''" @click="changecur(2, '已结束')">
          已结束
          <span>{{ colfunlist == undefined ? 0 : getfunnum('已结束') }}</span>
        </li>
      </ul>
      <div class="hidden-sm-and-down">
        <div class="fun-lists">
          <div
            class="fun-list"
            v-for="(item, index) in colfunlist.slice((pagecur-1)*pagecnt,pagecur*pagecnt)"
            :key="index"
            v-show="curtext == '' ? true : curtext == getstatus(item.STARTTIME, item.ENDTIME).text ? true : false"
          >
            <el-row class="fun-list-cnt">
              <el-col :xs="6" :sm="6" :md="6">
                <div class="code-pic"><img :src="item.ONE" @click="goactive(item)" /></div>
              </el-col>
              <el-col :xs="18" :sm="18" :md="18">
                <div class="fun-cnts">
                  <p class="cnts-tit" @click="goactive(item)">
                    {{ item.TITLE }}
                    <i :class="getstatus(item.STARTTIME, item.ENDTIME).type == 1 || getstatus(item.STARTTIME, item.ENDTIME).type == 3 ? 'fun-status-on' : 'fun-status-off'">{{ getstatus(item.STARTTIME, item.ENDTIME).text }}</i>
                  </p>
                  <div class="cnts-lists">
                    <div class="cnts-list">
                      <p class="cnt-text">
                        <span>报名时间</span>
                        {{ gettime(item.STARTTIME2) }}
                      </p>
                    </div>
                    <div class="cnts-list">
                      <p class="cnt-text">
                        <span>活动地点</span>
                        {{ item.ADDRESS }}
                      </p>
                    </div>
                    <div class="cnts-list">
                      <p class="cnt-text">
                        <span>活动时间</span>
                        {{ gettime(item.STARTTIME) }}-{{ gettime(item.ENDTIME) }}
                      </p>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <ul class="operate-list" v-if="getstatus(item.STARTTIME, item.ENDTIME).type != 2">
              <li>
                <div>
                  <i class="el-icon-s-tools"></i>
                  操作区
                </div>
              </li>
              <li @click="goactive(item)">
                <div>
                  <i class="el-icon-s-flag"></i>
                  马上参与
                </div>
              </li>
              <li @click="stopjoin(item)">
                <div>
                  <i class="el-icon-remove"></i>
                  取消收藏
                </div>
              </li>
            </ul>
            <ul class="operate-list" v-else>
              <li>
                <div>
                  <i class="el-icon-s-tools"></i>操作区
                </div>
              </li>
              <li>
                <div>
                  <i class="el-icon-remove" @click="stopjoin(item)"></i>取消收藏
                </div>
              </li>
            </ul>
          </div>
          <!--            <div class="fun-list">
              <el-row class="fun-list-cnt">
                <el-col :xs="6" :sm="6" :md="6">
                  <div class="code-pic">
                    <img src="../../assets/images/P/code.png" />
                  </div>
                </el-col>
                <el-col :xs="18" :sm="18" :md="18">
                  <div class="fun-cnts">
                    <p class="cnts-tit">sportsy fun阳光有氧,双人瑜伽之旅sportsy fun阳光有氧,双人瑜伽之旅sportsy fun阳光有氧,双人瑜伽之旅sportsy fun阳光有氧,双人瑜伽之旅<i class="fun-status-off">已结束</i></p>
                    <div class="cnts-lists">
                      <div class="cnts-list">
                        <p class="cnt-text"><span>参与时间</span>2019年3月1日08:30</p>
                      </div>
                      <div class="cnts-list">
                        <p class="cnt-text"><span>活动地点</span>深圳市民中心</p>
                      </div>
                      <div class="cnts-list">
                        <p class="cnt-text"><span>活动时间</span>03月01日 09:00-04月05日 09:00</p>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <ul class="operate-list">
                <li>
                  <div>
                    <i class="el-icon-s-tools"></i>操作区
                  </div>
                </li>
                <li>
                  <div>
                    <i class="el-icon-remove"></i>取消参与
                  </div>
                </li>
              </ul>
            </div> -->
        </div>
        <el-pagination hide-on-single-page background layout="prev, pager, next" :total="colfunlist.length" :page-size="pagecnt" :current-page="pagecur" @prev-click="prev()" @next-click="next()" @current-change="changepage"></el-pagination>
      </div>
      <div class="fun-lists-m hidden-md-and-up">
        <ul>
          <li v-for="(item, index) in colfunlist" :key="index" v-show="curtext == '' ? true : curtext == getstatus(item.STARTTIME, item.ENDTIME).text ? true : false">
            <el-row type="flex">
              <el-col :span="8" class="code-pic-m"><img :src="item.ONE" @click="goactive(item)" /></el-col>
              <el-col :span="16" class="cnts-list">
                <div class="cnts-tit" @click="goactive(item)">
                  <p>{{ item.TITLE }}</p>
                </div>
                <p class="cnts1-m">
                  活动时间
                  <span>{{ gettime(item.STARTTIME) }}-{{ gettime(item.ENDTIME) }}</span>
                </p>
                <p class="cnts2-m">
                  活动地点
                  <span>{{ item.ADDRESS }}</span>
                </p>
                <p class="cnts2-m">
                  活动状态
                  <span :class="getstatus(item.STARTTIME, item.ENDTIME).type == 1 || getstatus(item.STARTTIME, item.ENDTIME).type == 3 ? 'inspect' : 'off-inspect'">{{ getstatus(item.STARTTIME, item.ENDTIME).text }}</span>
                </p>
                <el-row type="flex">
                  <el-col :span="24" class="cnts-btn"><div class="lookqr-btn" @click="p4gosign()" v-if="getstatus(item.STARTTIME, item.ENDTIME).type != 2">马上参加</div></el-col>
                </el-row>
              </el-col>
            </el-row>
          </li>
          <!-- <li>
            <el-row type="flex">
              <el-col :span="8" class="code-pic-m"><img src="../../assets/images/P/code.png" /></el-col>
              <el-col :span="16" class="cnts-list">
                <div class="cnts-tit"><p>阳光有氧双人瑜伽之旅之旅阳光有氧双人瑜伽之旅之旅</p></div>
                <p class="cnts1-m">
                  活动时间
                  <span>03月01日 09:00-04月05日 09:00</span>
                </p>
                <p class="cnts2-m">
                  活动地点
                  <span>深圳市民中心</span>
                </p>
                <p class="cnts2-m">
                  活动状态
                  <span class="off-inspect">已结束</span>
                </p>
              </el-col>
            </el-row>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { colfunlist, delcolfun } from '../../api'
import qs from 'qs'
export default {
  name: 'cityG4',
  data () {
    return {
      pagecur: 1,
      pagecnt: 10,
      colfunlist: [],
      cur: 0,
      curtext: ''
    }
  },
  inject: ['reload'],
  mounted () {
    this.$nextTick(() => {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
        this.$confirm('请先登录账号,点击下方按钮跳转到登录界面', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          center: true,
          showCancelButton: false,
          showClose: false,
          closeOnPressEscape: false
        }).then(() => {
          this.$router.push('/Login')
        })
      } else {
        // let id = '47bc75a3494d478dbb251e68d4aeccf3'
        let id = userInfo.userId
        colfunlist(id).then(res => {
          this.colfunlist = res.data
        })
      }
      // setTimeout(() => {
      //   console.log(this.$route)
      // },500)
    })
  },
  methods: {
    changecur (num, str) {
      this.cur = num
      this.curtext = str
    },
    gettime (date) {
      var newdate = new Date(date)
      var year = newdate.getFullYear()
      var month = newdate.getMonth() + 1
      var day = newdate.getDate()
      var hour = newdate.getHours() < 10 ? '0' + newdate.getHours() : newdate.getHours()
      var minute = newdate.getMinutes() < 10 ? '0' + newdate.getMinutes() : newdate.getMinutes()
      var currentTime = year + '年' + month + '月' + day + '日  ' + hour + ':' + minute
      return currentTime
    },
    getstatus (starttime, endtime) {
      let newtime = new Date().valueOf()
      let start = new Date(starttime).valueOf()
      let end = new Date(endtime).valueOf()
      let status
      if (newtime >= start && newtime <= end) {
        status = {
          type: 1,
          text: '开放报名'
        }
      } else if (newtime > end) {
        status = {
          type: 2,
          text: '已结束'
        }
      } else if (newtime < start) {
        status = {
          type: 3,
          text: '开放报名'
        }
      }
      return status
    },
    getfunnum (str) {
      let len = this.colfunlist.length
      let list = []
      for (let i = 0; i < len; i++) {
        let type = this.getstatus(this.colfunlist[i].STARTTIME, this.colfunlist[i].ENDTIME).text
        if (type == str) {
          list.push(this.colfunlist[i])
        }
      }
      return list.length
    },
    prev(){
      this.pagecur--
    },
    next(){
      this.pagecur++
    },
    goactive(item){
      let id = item.MAKEACTIVITY_ID
      let uid = item.USERID
      this.$router.push({
        name:'cityQ5',
        query:{
          id :id,
          uid :uid
        }
      })
    },
    stopjoin (item) {
      let uid = JSON.parse(localStorage.getItem('userInfo')).userId
      this.$confirm('此操作将取消收藏该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let id = item.MAKEACTIVITY_ID
        let list = {
          ACTIVITYID: id,
          USERID: uid
        }
        console.log(list)
        delcolfun(qs.stringify(list)).then(res => {
          if(res.data.result == 'ok'){
            this.reload()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作！',
          center: true
        })
      })
    },
    goactive(item){
      let id = item.MAKEACTIVITY_ID
      let uid = item.USERID
      this.$router.push({
        name:'cityQ5',
        query:{
          id :id,
          uid :uid
        }
      })
    },
	changepage(e){
	  this.pagecur = e
	}
  }
}
</script>

<style lang="stylus" scoped src="../../assets/css/CityG.styl">

</style>
<style scoped lang="stylus">
@media screen and (max-width: 990px){
  .G-container.zh {
    .main-p{
      &.main-p4{
        .main-tab{
          li{
            width (100/3)%
          }
        }
      }
    .fun-lists-m{
      li{
        padding .4rem 0
        border-bottom 0.0185rem solid #e2e2e2
        .code-pic-m{
            display flex
            align-items baseline
            img{
              width 100%
              display block
            }
          }
          .cnts-list{
            padding 0 0 0 .2rem
            .cnts-tit{
              display flex
              height .8rem
              align-items center
              p{
                color #474747
                font-size 0.3333rem
                position relative
                word-break break-all
                padding 0 0 0 .4rem
                line-height 1
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                &::before{
                  content ''
                  border-left 0.0555rem solid #a5cd39
                  height 100%
                  left 0
                  top 0
                  position absolute
                }
              }
            }
            .cnts1-m,
            .cnts2-m{
              font-size 0.2962rem
              color #a5cd39
              line-height 1
              padding .2rem 0 0 0
              word-break break-all
              span{
                color #8d8d8d
                margin 0 0 0 .2rem
                &.inspect{
                  background #ffa300
                  color #fff
                  padding 0 .1rem

                }
                &.no-inspect{
                  color #ffa300

                }
                &.stop-inspect{
                  color #8d8d8d

                }
                &.fail-inspect{
                  color #ff0000
                  margin 0 0 0 .45rem
                }
                &.off-inspect{
                  background #c6c6c6
                  color #fff
                  padding 0 .1rem
                }
                i{
                  color #c8c8c8
                  font-size 0.3703rem
                  display inline-block
                  margin 0 0 0 .2rem
                  cursor pointer
                }
              }
            }
            .cnts1-m{
              display flex
              span{
                width 76%
              }
            }
            .cnts-btn{
              display flex
              justify-content end
              align-items flex-end
              padding .4rem 0 0 0
              .lookqr-btn{
                background #a5cd39
                color #fff
                font-size 0.3333rem
                display inline-block
                padding 0.1rem 0.7rem
              }
            }
          }
        }
      }
    }
  }
}
</style>
