import { render, staticRenderFns } from "./CityG5.vue?vue&type=template&id=47823fd8&scoped=true&"
import script from "./CityG5.vue?vue&type=script&lang=js&"
export * from "./CityG5.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/CityG.styl?vue&type=style&index=0&id=47823fd8&lang=stylus&scoped=true&"
import style1 from "./CityG5.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47823fd8",
  null
  
)

export default component.exports